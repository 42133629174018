import React from 'react';
import { Link, graphql, StaticQuery } from "gatsby";
import DefaultLayout from '../layouts/default';
import Breadcrumbs from '../components/Custom/Navigation/Breadcrumbs';

export default ({ location }) => {
//  const dispatch = useDispatch();

//   React.useEffect(() => {

//   });



  return (
    <DefaultLayout location={location}>

        <Breadcrumbs location={location} />
        
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>
								The headquarters?
							</h1>
                            <p>
								&mdash; Well, Network Ventures is a cooperative – our members are spread all over the place. However, this is our headquarters:
							</p>
							<h4 class="mt-50">Network Ventures</h4>
							<p>Leopoldstraße 24<br/>
								6020 Innsbruck<br/>
								Austria
							</p>
							<p class="mt-25">
								www.ntwk.ventures<br/>
								<a href="mailto:hi@ntwk.ventures">hi@ntwk.ventures</a>
							</p>
							<nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase mt-50">
								<ul>
									<li>
										<Link to={"/imprint"}>
											Imprint
											<span className="icon icon-arrow-right"></span>
										</Link>
									</li>
								</ul>
							</nav>
						</div>
					</div>

				</section>
			</div>

    </DefaultLayout>
  );
};

